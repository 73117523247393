export default {
    install(app, _) {
        app.config.globalProperties.$dynamicTitle = (title, dynamicPart) => {
            const a = title.split(" ");
            const i = a.indexOf("[]");
            a[i] = dynamicPart;
            const b = a.join(" ");
            return b;
        };
    },
};
