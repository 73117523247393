import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import smartComponentsLoader from "../views/view-router/smartComponentsLoader.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: "/en/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/",
        name: "en",
        meta: { bodyClass: "page" },
        component: smartComponentsLoader,
        props: true,
    },

    {
        path: "/ge/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/",
        name: "ge",
        meta: { bodyClass: "page" },
        component: smartComponentsLoader,
        props: true,
    },

    
    {
        path: "/ru/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/",
        name: "ru",
        meta: { bodyClass: "page" },
        component: smartComponentsLoader,
        props: true,
    },

    {
        path: "/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/",
        name: "no_name",
        meta: { bodyClass: "page" },
        component: smartComponentsLoader,
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
