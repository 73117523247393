import axios from "axios";
import get from "lodash/get";
import conf from "./conf";
import { useMenuStore } from "../store/pinia/menu/index";

export default {
    async postData(path = "", data = {}) {
        return this.reqAxios(path, data).then((data) => {
            return data;
        });

    },

    async reqAxios(path = "", data = {}) {
        const response = await axios(conf.apiUrl + path, {
            method: "POST",
            headers: {

            },
            data: data,
        
        }).then((response) => {
            return response;
        });

        if (response.status < 300) {
            return { data: response.data, status: response.status };
        }
    },

    /** Get translated strings
     * If a string does not exist, make a request to create one */
    tr(wordkey: string, params?: string[]) {
        if (!wordkey || wordkey === "") return wordkey;

        wordkey = wordkey.trim();
        const store = useMenuStore();

    

        //@ts-ignore
        if (!store.translations[store.locale])
            return this.sprintf(wordkey, params);


        if (get(store.translations, [store.locale, wordkey]))
            return this.sprintf(
                get(store.translations, [store.locale, wordkey]),
                params
            );
        if (get(store.translations, [store.locale, wordkey.toLowerCase()]))
            return this.sprintf(
                get(store.translations, [store.locale, wordkey.toLowerCase()]),
                params
            );

        this.postData("main/adwrd", { wrd: wordkey });
        return this.sprintf(wordkey, params);
    },

    sprintf(string: string, params: string[]) {
        if (!Array.isArray(params)) return string;
        for (let i in params) string = string.replace("%s", params[i]);
        return string;
    },
};
