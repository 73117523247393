/**
 * @author Irakli Kandelaki
 */

export default {
    data(){
        return{
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }
    },
    created(){
        window.addEventListener("resize", this.handle_resize);
    },
    methods: {
        handle_resize(){
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        }
    }
        
    
}