import {defineStore} from "pinia";

export const useScale = defineStore("scale", {
    state: () => ({
        _animated: false, _opened: false, _transformY: 0
    }), getters: {
        animated(state) {
            return state._animated;
        }, opened(state) {
            return state._opened;
        }, transformY(state) {
            return state._transformY;
        },

    }, actions: {
        setAnimated(value) {
            this._animated = value;
        }, setOpened(value) {
            this._opened = value;
        }, setTransformY(value) {
            this._transformY = value;
        },
    },
});
