import get from "lodash/get";
import find from "lodash/find";
import size from "lodash/size";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import replace from "lodash/replace";

import { StateInterface } from "../../ts/interfaces/state.interface";

const localizedMenuItem = (menuItem: any, locale: string) => {
    return {
        title: get(menuItem, ["titles", locale, "title"]),
        optional_title: get(menuItem, ["titles", locale, "optional_title"]),
        bannerData: get(menuItem, ["titles", locale, "banner"]),

        teaser: get(menuItem, ["titles", locale, "teaser"]),
        url: get(menuItem, ["url_slug"]),
        full_url_nohash: get(menuItem, ["full_url_nohash"]),
        full_url: get(menuItem, ["full_url"]),
        full_url_clean: get(menuItem, ["full_url_clean"]),
        image: get(menuItem, ["image_" + locale]),
        configs: get(menuItem, ["configs"]),
        id: get(menuItem, ["id"]),
        sort: get(menuItem, ["sort"]),
        pid: get(menuItem, ["pid"]),
        svg_regular: get(menuItem, ["titles", locale, "svg_regular"]),
        svg_hover: get(menuItem, ["titles", locale, "svg_hover"]),
        secondary_data: get(menuItem, ["secondary_data"]),
        secondary_template: get(menuItem, ["secondary_template"]),
        primary_template: get(menuItem, ["primary_template"]),
        media: get(menuItem, ["media"]),
        seo: get(menuItem, ["seo", locale]),
        shortlink: get(menuItem, ["shortlink"]),
        set_home: get(menuItem, ["set_home"]),
    };
};

function menu_mutation(indxMenu: { [key: string]: any }, locale: string) {
    for (let i in indxMenu) {
        let loop = 0;
        let tmp = indxMenu[i];
        let lopMenu = indxMenu[i];
        // let lastPid = tmp.pid;
        indxMenu[i].secondary_content_template = get(indxMenu[i], [
            "secondary_template",
        ]);

        let paths = [get(tmp, "url_slug")];
        let hash = get(tmp, ["titles", "hash"]) ? "#" + tmp.titles.hash : "";

        indxMenu[i].shortlink = 0; /// 0: regular link; 1: in site redirect; 2 outer redirect with http
        indxMenu[i].regular_path = ""; /// regular path build from slugs /// it will be individual /// used to find exact menu from browser url path
        indxMenu[i].regular_path_localized = "";

        /// find full path of menu item
        while (loop++ < 20) {
            tmp = find(indxMenu, { id: tmp.pid });
            if (!tmp) break;
            paths.unshift(get(tmp, "url_slug"));
            if (!tmp.pid || tmp.pid == 0) break;
        }

        /// generate regular urls
        indxMenu[i].regular_path = "/" + paths.join("/");
        indxMenu[i].regular_path_localized =
            "/" + locale + indxMenu[i].regular_path;

        ///// menu is redirectable
        if (
            get(indxMenu[i], "redirect_url") &&
            size(indxMenu[i].redirect_url)
        ) {
            if (
                indxMenu[i]?.redirect_url?.indexOf("ttp://") > 0 ||
                indxMenu[i]?.redirect_url?.indexOf("ttps://") > 0
            ) {
                /// menu is redirectable to some http address
                indxMenu[i].shortlink = 2;
                indxMenu[i].full_url = get(lopMenu, "redirect_url");
                indxMenu[i].full_url_clean = get(lopMenu, "redirect_url");
            } else {
                /// menu redirects inside website
                indxMenu[i].shortlink = 1;
                indxMenu[i].full_url =
                    "/" + locale + "/" + get(lopMenu, "redirect_url");
                indxMenu[i].full_url_clean = get(lopMenu, "redirect_url");
            }
        } else {
            indxMenu[i].full_url_nohash = indxMenu[i].regular_path_localized;
            indxMenu[i].full_url = indxMenu[i].full_url_nohash + hash;
            indxMenu[i].full_url_clean = indxMenu[i].regular_path;
        }
    }

    //@ts-ignore
    const translatedIndxMenu: any[] = [];
    indxMenu.forEach((menu: any) => {
        const translatedMenuItem = localizedMenuItem(menu, locale);
        translatedIndxMenu.push(translatedMenuItem);
    });

    indxMenu = orderBy(translatedIndxMenu, ["sort"], ["asc"]);
    return indxMenu;
}
function set_current_menu_locale_paths(state: StateInterface) {
    if (!state.indx.menus) return false;

    let tmp = get(
        find(state.indx.menus, { id: state.selected_menu.id }),
        "full_url_clean"
    );

    if (includes(window.location.pathname, "singleview")) {
        tmp = replace(window.location.pathname, "/" + state.locale + "/", "/");
    }

    state.selected_menu_locales = {};

    for (let locale in state.indx.locales) {
        state.selected_menu_locales[locale] = tmp ? "/" + locale + tmp : "/";
    }

    // return false;
}
function set_current_content_from_buffer(state: StateInterface) {
    state.current_content = JSON.parse(
        JSON.stringify(state.current_content_buffer)
    );
}
function set_menu_full_urls(state: StateInterface) {
    let i: keyof typeof state.indx.menus;
    for (i in state.indx.menus) {
        let loop = 0;
        let tmp = state.indx.menus[i];
        let lopMenu = state.indx.menus[i];
        // let lastPid = tmp.pid;
        state.indx.menus[i].secondary_content_template = get(
            state.indx.menus[i],
            ["secondary_template"]
        );

        let paths = [get(tmp, "url_slug")];
        let hash = get(tmp, ["titles", "hash"]) ? "#" + tmp.titles.hash : "";

        state.indx.menus[i].shortlink = 0; /// 0: regular link; 1: in site redirect; 2 outer redirect with http
        state.indx.menus[i].regular_path = ""; /// regular path build from slugs /// it will be individual /// used to find exact menu from browser url path
        state.indx.menus[i].regular_path_localized = "";

        /// find full path of menu item
        while (loop++ < 20) {
            tmp = find(state.indx.menus, { id: tmp.pid });
            if (!tmp) break;
            paths.unshift(get(tmp, "url_slug"));
            if (!tmp.pid || tmp.pid == 0) break;
        }

        /// generate regular urls
        state.indx.menus[i].regular_path = "/" + paths.join("/");
        state.indx.menus[i].regular_path_localized =
            "/" + state.locale + state.indx.menus[i].regular_path;

        ///// menu is redirectable
        if (
            get(state.indx.menus[i], "redirect_url") &&
            size(state.indx.menus[i].redirect_url)
        ) {
            if (
                state.indx.menus[i].redirect_url.indexOf("ttp://") > 0 ||
                state.indx.menus[i].redirect_url.indexOf("ttps://") > 0
            ) {
                /// menu is redirectable to some http address
                state.indx.menus[i].shortlink = 2;
                state.indx.menus[i].full_url = get(lopMenu, "redirect_url");
                state.indx.menus[i].full_url_clean = get(
                    lopMenu,
                    "redirect_url"
                );
            } else {
                /// menu redirects inside website
                state.indx.menus[i].shortlink = 1;
                state.indx.menus[i].full_url =
                    "/" + state.locale + "/" + get(lopMenu, "redirect_url");
                state.indx.menus[i].full_url_clean = get(
                    lopMenu,
                    "redirect_url"
                );
            }
        } else {
            state.indx.menus[i].full_url_nohash =
                state.indx.menus[i].regular_path_localized;
            state.indx.menus[i].full_url =
                state.indx.menus[i].full_url_nohash + hash;
            state.indx.menus[i].full_url_clean =
                state.indx.menus[i].regular_path;
        }
    }

    state.indx.menus = orderBy(state.indx.menus, ["sort"], ["asc"]);

    return false;
}

export {
    menu_mutation,
    set_current_menu_locale_paths,
    set_current_content_from_buffer,
    set_menu_full_urls,
};
