import { mapState } from "pinia";
import { useMenuStore } from "../store/pinia/menu/index";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";


export default {
    data() {
        return {
            menuStatus: { level1: 0, level2: 0, level3: 0, level4: 0 },
            nestedMenus: {},
            rootMenus: [],
            topMenus: [],
            footerMenus: [],
            footerBottom: [],
            menuTitles: {},
            pathEndKeywords: ["singleview", "apview"],
        };
    },

    computed: {
        ...mapState(useMenuStore, ["getList", "getFromList", "indx", "locale"]),
    },

    methods: {
        detectLineBreak(value) {
            if (!value) return "";
            let array = value.match(/[^\r\n]+/g);

            array.forEach(function (item, index, arr) {
                if (index != array.length - 1) {
                    arr[index] = arr[index] + " </br> ";
                }
            });
            return array.join(" ");
        },

        getMenuFlat: function (menuName, pid) {
            if (!this.indx.menus || !menuName) return [];
            let menus = this.indx.menus;
            let res = [];

            for (let i in menus) {
                if (menus[i].menu_type != menuName) continue;

                if (pid != undefined && menus[i].pid != pid) continue;

                res.push(this.localizedMenuItem(menus[i]));
            }
            res = orderBy(
                res.filter(
                    (item) => !includes(item?.configs, "hide-from-menu")
                ),
                ["sort"],
                ["asc"]
            );
            return res;
        },

        getMenuFlat2: function (menuName, pid) {
            if (!this.indx.menus || !menuName) return [];
            let menus = this.getList({ key: "indx.menus" });

            let res = [];
            for (let i in menus) {
                if (menus[i].key != menuName) continue;

                if (pid != undefined && menus[i].pid != pid) continue;

                res.push(this.localizedMenuItem(menus[i]));
            }
            res = orderBy(res, ["sort"], ["asc"]);

            return res;
        },

        makeMenuTree: function (menu_type = "main_menu") {
            let data = this.getMenuFlat(menu_type);

            data = orderBy(data, ["sort"], ["asc"]);

            if (typeof data != "object") return [];
            const idMapping = data.reduce((acc, el, i) => {
                acc[el.id] = i;
                return acc;
            }, {});
            let root = [];
            data.forEach((el) => {
                if (idMapping[el.pid] == undefined) {
                    el.pid = 0;
                }
                if (!el.pid || el.pid === null || el.pid == 0) {
                    root.push(el);
                    return;
                }

                const parentEl =
                    data[
                        idMapping[el.pid] && el.pid != el.id
                            ? idMapping[el.pid]
                            : 0
                    ];
                parentEl.children = [...(parentEl.children || []), el];
            });

            return root;
        },

        setNestedMenus() {
            this.nestedMenus = {};

            let menu = this.getList({ key: "indx.menus" });

            for (let i in menu) {
                if (!this.nestedMenus[menu[i].pid])
                    this.nestedMenus[menu[i].pid] = [];
                this.nestedMenus[menu[i].pid].push(
                    this.localizedMenuItem(menu[i])
                );
            }
            this.rootMenus = this.getMenuFlat("main", 0);
            this.topMenus = this.getMenuFlat("top", 0);
            this.footerMenus = this.getMenuFlat("footer", 0);
            this.footerBottom = this.getMenuFlat("footer_bottom", 0);
        },

        getMenuById: function (id) {
            let menu = _.find(this.indx.menus, { id: id });
            return this.localizedMenuItem(menu);
        },

        localizedMenuItem(menuItem) {
            let locale = this.locale;
            return {
                title: _.get(menuItem, ["titles", locale, "title"]),
                optional_title: _.get(menuItem, ["titles", locale, "optional_title"]),
                bannerData: _.get(menuItem, ["titles", locale, "banner"]),

                teaser: _.get(menuItem, ["titles", locale, "teaser"]),
                url: _.get(menuItem, ["url_slug"]),
                full_url_nohash: _.get(menuItem, ["full_url_nohash"]),
                full_url: _.get(menuItem, ["full_url"]),
                full_url_clean: _.get(menuItem, ["full_url_clean"]),
                image: _.get(menuItem, ["image_" + locale]),
                configs: _.get(menuItem, ["configs"]),
                id: _.get(menuItem, ["id"]),
                sort: _.get(menuItem, ["sort"]),
                pid: _.get(menuItem, ["pid"]),

                secondary_data: _.get(menuItem, ["secondary_data"]),
                secondary_template: _.get(menuItem, ["secondary_template"]),
                primary_template: _.get(menuItem, ["primary_template"]),
                media: _.get(menuItem, ["media"]),
                seo: _.get(menuItem, ["seo", locale]),
                shortlink: _.get(menuItem, ["shortlink"]),
                set_home: _.get(menuItem, ["set_home"]),
            };
        },

        hasChildren(id) {
            let result = 0;
            for (let i in this.indx.menus) {
                if (id == this.indx.menus[i].pid) result++;
            }
            return result;
        },

        getFullUrl(menu, locale) {
            if (menu.full_url) return menu.full_url;
            if (!menu.id) return false;
            let tmp = menu;

            tmp = this.getFromList({
                key: "indx.menus",
                val: menu.id,
            });

            return _.get(tmp, "full_url");
        },

        getFullUrl2(menuId) {
            let tmp = this.getFromList({
                key: "indx.menus",
                val: menuId,
            });
            return _.get(tmp, "full_url");
        },

        getMenuFromPath(routeParams) {
            let menus = this.getList({ key: "indx.menus" });
            let pathNoLan = this.getPath(0);
            let selectedMenu = {};
            for (let i in menus) {
                if (
                    pathNoLan === "/" &&
                    Number(_.get(menus[i], "set_home")) === 1
                ) {
                    selectedMenu = this.localizedMenuItem(menus[i]);
                    break;
                } else if (pathNoLan === menus[i].regular_path) {
                    selectedMenu = this.localizedMenuItem(menus[i]);
                    break;
                }
            }

            if (_.get(selectedMenu, "shortlink") == 1) {
                this.$router.push(selectedMenu.full_url);
                // return false; ეს გავაკომენტარე ბაგავდა რედაირექტის დროს
                return selectedMenu;
            }

            selectedMenu.routeParams = routeParams ? routeParams : {};
            return selectedMenu;
        },

        getBreadCrumbs(menu) {
            if (!menu || !menu.id) return false;
            let menus = this.getList({ key: "indx.menus" });
            let ret = [menu];

            if (!menu.pid) return ret;

            let lastMenu = menu;
            let j = 0;

            while (true) {
                for (let i in menus) {
                    if (menus[i].id != lastMenu.pid) continue;
                    lastMenu = menus[i];
                    ret.unshift(this.localizedMenuItem(lastMenu));
                    break;
                }
                if (++j > 50) break;
                if (!lastMenu.pid) break;
            }

            return ret;
        },

        getPath(withLan) {

            let path = [];

            for (let i in this.$route.params) {
                if (includes(this.pathEndKeywords, this.$route.params[i])) break;
                if (!this.$route.params[i]) break;

                path.push(this.$route.params[i]);
            }

            if (withLan) path.unshift(this.$route.name);

            return "/" + path.join("/");
        },

        setUrl(data) {
            let params = Object.values(
                JSON.parse(JSON.stringify(this.$route.params))
            );

            let additional = [];
            for (let i in data) {
                let index = _.indexOf(params, i);
                if (index >= 0) {
                    params[index + 1] = data[i];
                } else {
                    additional.push("/" + i + "/" + data[i]);
                }
            }

            return (
                "/" +
                this.$route.name +
                "/" +
                params.join("/") +
                additional.join("/")
            );
        },
    },
};
